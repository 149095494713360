<template>
  <div>
    <h2>Search field</h2>
    <p>
      The EverWatch Logs Page includes an easily accessible search field where
      you can enter any search term as well as a date to search through all
      EverWatch logs from the previous 30 days. Once you have entered a search
      term, click the [arrow button] or press the enter key on your keyboard.
      Advanced search functions are available by clicking the square with the
      [magnifying glass] outside of the search box.
    </p>

    <v-img
      content-class="help-image-style"
      class="help-image"
      eager
      contain
      src="../../images/help/logs_search.png"
      transition="fade-transition"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <h2>Division Buttons</h2>
    <p>
      The EverWatch Logs Page includes a list of clickable buttons for each RPD
      division, each of which, when clicked, takes you to a page specific to
      that division. If a [division button] has a number in the upper right
      corner, it shows how many new log entries the division has published in
      the last 24 hours.
    </p>
    <v-img
      content-class="help-image-style"
      class="help-image"
      eager
      contain
      src="../../images/help/logs_divisions.png"
      transition="fade-transition"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <h2>Drafts Button</h2>
    <p>
      The EverWatch Logs Page includes a white [draft button] that, when
      clicked, opens any logs that had previously been saved as drafts in the
      log editor. Logs saved as drafts are intended to be saved temporarily so
      that they can be edited and published later. If the [draft button] has a
      number in the upper right corner, it represents the number of draft logs
      you currently have.
    </p>
    <v-img
      content-class="help-image-style"
      class="help-image"
      eager
      contain
      src="../../images/help/draft_button.png"
      transition="fade-transition"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <h2>Floating Action Buttons</h2>
    <p>
      Every EverWatch page includes a list of floating action buttons that are
      always accessible. Depending on the page, some functions may be missing.
      Here's a brief description of each possible button action after you
      confirm it:
    </p>
    <ul>
      <li>
        [Edit]: After selecting one or more logs, this button will appear, and,
        when clicked, it opens the logs in the log editor for editing.
      </li>
      <li>[Home]: When clicked, it takes you to the EverWatch Logs page.</li>
      <li>
        [Add]: When clicked, it opens the log editor with a new log created.
      </li>
      <li>[Delete]: When clicked, it deletes the selected log.</li>
      <li>
        [Menu]: When clicked, it opens a menu with additional buttons. The
        buttons within the menu are as follows:
        <ul>
          <li>
            [Help]: When clicked, opens a contextual help page with information
            about the current page.
          </li>
          <li>
            [Email]: After selecting one or more logs, this button will appear,
            and, when clicked, it opens the logs in the email dialog to generate
            an email.
          </li>
          <li>
            [Print]: After selecting one or more logs, this button will appear,
            and, when clicked, it opens the logs in a print view for viewing and
            printing.
          </li>
          <li>[Logout]: When clicked, this logs you out of EW.</li>
        </ul>
      </li>
    </ul>
    <br />
    <v-img
      content-class="help-image-style"
      class=""
      eager
      contain
      src="../../images/help/logs_fabs.png"
      transition="fade-transition"
      max-height="400px"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .help-image {
  max-height: 150px;
  min-height: 150px;
}
</style>

<script>
/* eslint-disable */
export default {
  name: "logs_home_page_help",
  data() {
    return {};
  },
  methods: {
    init() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
